import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { useSprings, animated, to as interpolate } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'

import * as utils from './util/photoStackUtils';


const PHOTOS = [
  'https://df6yevl24pl4a.cloudfront.net/filer_public/71/0a/710ac977-7f63-4e6e-9078-e95569ca7a34/middle-aged-caucasian-woman.webp',
  'https://df6yevl24pl4a.cloudfront.net/filer_public/81/df/81df3ea6-bcd4-4a6e-bd79-0e14f08f5db2/asian-woman-smiling.webp',
  'https://df6yevl24pl4a.cloudfront.net/filer_public/9d/2b/9d2be34d-8d8c-439a-807f-f95b4058cf77/smiling-older-caucasian-woman.webp',
  'https://df6yevl24pl4a.cloudfront.net/filer_public/7d/b2/7db2adff-2f85-42b9-8c28-da9d2af67e0f/african-woman.webp'
];




export default function Deck({ cards }) {
  const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out
  const [props, api] = useSprings(cards.length, (i) => ({
    ...utils.to(i),
    from: utils.from(i)
  })) // Create a bunch of springs using the helpers above

  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useDrag(({ args: [index], active, movement: [mx], direction: [xDir], velocity: [vx] }) => {
    const trigger = vx > 0.2 // If you flick hard enough it should trigger the card to fly out
    if (!active && trigger) gone.add(index) // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
    api.start((i) => {
      if (index !== i) return // We're only interested in changing spring-data for the current spring
      const isGone = gone.has(index)
      const x = isGone ? (200 + window.innerWidth) * xDir : active ? mx : 0 // When a card is gone it flys out left or right, otherwise goes back to zero
      const rot = mx / 100 + (isGone ? xDir * 10 * vx : 0) // How much the card tilts, flicking it harder makes it rotate faster
      const scale = active ? 1.1 : 1 // Active cards lift up a bit
      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: { friction: 50, tension: active ? 800 : isGone ? 200 : 500 }
      }
    })
    if (!active && gone.size === cards.length)
      setTimeout(() => {
        gone.clear()
        api.start((i) => utils.to(i))
      }, 600)
  })
  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return (
    <>
      {props.map(({ x, y, rot, scale }, i) => (
        <animated.div key={i} style={{ x, y }}>
          {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
          <animated.div
            {...bind(i)}
            style={{
              transform: interpolate([rot, scale], utils.trans),
              backgroundImage: `url(${cards[i]})`,
              width: '440px',
              height: '440px',
              // width: cards[i].orientation === 'portrait' ? '240px' : '320px',
              // height: cards[i].orientation === 'portrait' ? '320px' : '240px'
            }}
          />
        </animated.div>
      ))}
    </>
  )
}








const photoDeck = document.querySelector('#photo-deck');
if (photoDeck !== null) {
  const container = ReactDOM.createRoot(photoDeck);
  container.render(
    <>
      <Deck cards={PHOTOS} />
    </>
  );  
}